import React from 'react';
import HeroFull from './home-components/hero-full';
import Valores from './home-components/valores';
import MyV from './home-components/myv';
import Experiencia from './home-components/experiencia';
import Servicios from './home-components/servicios';
import Contact from './home-components/contact';



const HomeFull = () => {
    return <div>
        <HeroFull />
        <Valores />
        <MyV/>
        <Experiencia />
        <Servicios />
        <Contact />
    </div>
}


export default HomeFull
