import React from 'react';
import Hero from './home-components/hero';
import Valores from './home-components/valores';
import MyV from './home-components/myv';
import Experiencia  from './home-components/experiencia';
import Servicios from './home-components/servicios';
import Contact from './home-components/contact';

const Home = () => {
    return <div>
        <Hero />
        <Valores />
        <MyV />
        <Servicios />
        <Experiencia />
        <Contact />
    </div>
}


export default Home
