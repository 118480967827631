import React, { Component } from 'react';

class Testimonial extends Component {
    render() {
        return <section id="servicios" className="pt100 pb100">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 col-md-8 col-sm-10 offset-lg-3 offset-md-2 offset-sm-1">
                    <div className="section-title text-center mb60">
                        <h2>SERVICIOS</h2>
                        <hr className="lines"/>
                    </div>
                </div>
            </div>
            <div className="row">
            <div className="col-sm-6">
                <div className="testimonial-item">
                    <blockquote>
                        <div style={{minHeight: "120px"}}>
                            <ol>
                                <li>
                                Servicio de Subcontratación Personal Especializado REPSE
                                </li>
                                <li>
                                Nómina Mixta
                                </li>
                                <li>
                                Deducibilidad
                                </li>
                            </ol>
                        </div>
                    </blockquote>
                    <h6>ADMINISTRACIÓN DE PERSONAL</h6>
                </div>
            </div>
            <div className="col-sm-6">
            <div className="testimonial-item">
                    <blockquote>
                        <div style={{minHeight: "120px"}}>
                            <ol>
                                <li>
                                Reducción, Administración y Ahorro de Costos  Operativos
                                </li>
                                <li>
                                Ahorro de Costos en Importaciones
                                </li>
                            </ol>
                        </div>
                    </blockquote>
                    <h6>EFICIENCIA FINANCIERA</h6>
                </div>
            </div>
            <div className="col-sm-6">
            <br/>
            <div className="testimonial-item">
                    <blockquote>
                        <div style={{minHeight: "120px"}}>
                            <ol>
                                <li>
                                Asesoría Financiera
                                </li>
                                <li>
                                Generación de Rendimientos
                                </li>
                            </ol>
                        </div>
                    </blockquote>
                    <h6>MCA INVESTMENT ADVISORS</h6>
                </div>
            </div>
            <div className="col-sm-6">
            <br/>
            <div className="testimonial-item">
                    <blockquote>
                        <div style={{minHeight: "120px"}}>
                            <ol>
                                <li>
                                Consultoría para venta de productos
                                </li>
                                <li>
                                Desarrollo de Negocios
                                </li>
                            </ol>
                        </div>
                    </blockquote>
                    <h6>CONSULTORÍA EN VENTAS Y DISTRIBUCIÓN</h6>
                </div>
            </div>
            <div className="col-sm-6">
            <br/>
            <div className="testimonial-item">
                    <blockquote>
                        <div style={{minHeight: "120px"}}>
                            <ol>
                                <li>
                                Servicios de Marketing Digital, BTL.
                                </li>
                                <li>
                                Esttrategias de Mercadotecnia
                                </li>
                            </ol>
                        </div>
                    </blockquote>
                    <h6>CAMPAÑAS DE MARKETING</h6>
                </div>
            </div>
            <div className="col-sm-6">
            <br/>
            <div className="testimonial-item">
                    <blockquote>
                        <div style={{minHeight: "120px"}}>
                            <ol>
                                <li>
                                Cobranza
                                </li>
                                <li>
                                Promoción y Venta
                                </li>
                                <li>
                                Prueba de Vida
                                </li>
                                <li>
                                Venta de Créditos
                                </li>
                            </ol>
                        </div>
                    </blockquote>
                    <h6>CAMPAÑAS CALL CENTER</h6>
                </div>
            </div>
            </div>
        </div>
    </section>
    }
}


export default Testimonial
