import React, { Component } from 'react';
class Pricing extends Component {
render () {



return <section id="experiencia" className="pt100 pb100 bg-light-gray">
    <div className="container">
        <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-10 offset-lg-3 offset-md-2 offset-sm-1">
                <div className="section-title text-center mb60">
                    <h2>EXPERIENCIA</h2>
                    <hr className="lines" />

                </div>
            </div>
        </div>
        <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">   
            <div>
                <div className="single-package color-white text-center md-m-25px-b">
                    <h4 className="package-head">CLIENTES SATISFECHOS</h4>
                </div>
            </div>
            <br/>
            <div>
                <div className="single-package color-white text-center md-m-25px-b">
                    <h4 className="package-head">PRESENCIA EN 21 ESTADOS DE LA REPÚBLICA MEXICANA</h4>
                </div>
            </div>
            <br/>
            <div >
                <div className="single-package color-white text-center md-m-25px-b">
                    <h4 className="package-head">14 AÑOS DE EXPERIENCIA</h4>
                </div>
            </div>   
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="single-package color-white text-center md-m-25px-b">
                    <h4 className="package-head">SECTORES</h4>
                    <ul className="package-feature">
                        <li>Construcción</li>
                        <li>Educación</li>
                        <li>Call Centers</li>
                        <li>Manufactura</li>
                        <li>Laboratorios</li>
                        <li>TI</li>
                        <li>Pequeña y Mediana Empresa</li>
                        <li>Industrias</li>
                    </ul>
                </div>
            </div>



        </div>
    </div>
</section>
}
}


export default Pricing
