import React, { useState,useRef} from 'react';
import homedata from '../../data/home.json';
import axios from "axios";
import ReCAPTCHA from 'react-google-recaptcha';
import Swal from 'sweetalert2';

const Contact = () => {
const [name, setName] = useState(null);
const [email, setEmail] = useState(null);
const [msg, setMsg] = useState(null);
const [phone, setPhone] = useState(null);
const [validationError,setValidationError] = useState({})
const [isdisabled, setIsDisabled] = useState(false)
const captchaRef = useRef(null)

const onClickSave = async (e) => {
e.preventDefault();
setIsDisabled(true)
const token = captchaRef.current.getValue();
const postData = { name, msg, email, phone,token};
axios
.post('http://127.0.0.1:8000/api/contact/send',
postData,
{ headers: { 'Content-Type': 'application/json' }}
)
.then(response => {
Swal.fire({
icon:"success",
text:"MENSAJE ENVIADO CORRECTAMENTE"
})
captchaRef.current.reset();
setValidationError({});
setIsDisabled(false)
setEmail('');
setMsg('');
setPhone('');
setName('');

})
.catch(({response})=>{
captchaRef.current.reset();
setIsDisabled(false)
if(response.status===422){
setValidationError(response.data.errors)
}else{
Swal.fire({
text:response.data.message,
icon:"error"
})
}
});

};
let contactInfo = homedata.contact
let publicUrl = process.env.PUBLIC_URL
let inlineStyle = {
backgroundImage: 'url('+publicUrl+contactInfo.bgImg+')'
}
return <section id="contact" className="pt100 pb100 bg-parallax" style={inlineStyle}>

    <div className="color-overlay opacity-8"></div>
    <div className="container">
        <div className="row">
            <div className="col-lg-8 offset-lg-2">
                <div className="contact-block text-center">
                    <div className="col-sm-12">
                        <h3>¿Necesitas hablar de algo?</h3>
                        <p className="subtitle">Para preguntas generales, por favor completa el siguiente formulario:</p>
                    </div>
                    <form id="contact-form" className="row" onSubmit={onClickSave}>
                        <div className="form-group text-center">
                            <div className="row">
                                <div className="col-md-6 mb-3">
                                    <input name="name" className="contact-name form-control input-box"
                                        id="contact-name" type="text" placeholder="Nombre" required="" onChange={(e)=>
                                            setName(e.target.value)}/>
                                </div>
                                <div className="col-md-6">
                                    <input name="email" className="contact-email form-control input-box"
                                        id="contact-email" type="email" placeholder="Email" required="" onChange={(e)=>
                                            setEmail(e.target.value)}/>
                                </div>
                                <div className="col-sm-12">
                                    <input name="telefono" className="contact-subject form-control input-box"
                                        id="contact-subject" type="text" placeholder="Telefono" required="" onChange={(e)=>
                                            setPhone(e.target.value)}/>
                                </div>
                                <div className="col-sm-12">
                                    <textarea name="message" className="contact-message form-control textarea-box" id="contact-message"
                                        placeholder="Mensaje" required="" onChange={(e)=> setMsg(e.target.value)}></textarea>
                                </div>
                                <div className="col-sm-12">
                                    <button className="primary-btn" type="submit"><i className="fa fa-paper-plane"
                                            aria-hidden="true" disabled={isdisabled}></i>Enviar</button>
                                    <div className="form_group_recaptcha col-sm-12 text-center">
                                        <ReCAPTCHA theme="light" sitekey="6Lcw7LcUAAAAAH8cBsyX7cYAQyDtC_XGRG3OY06L"
                                            ref={captchaRef} onExpired={()=> {
                                            captchaRef.current.reset();
                                            }}/>

                                    </div>
                                </div>
                                <div className="col-md-12 text-center">
                                    {
                                    Object.keys(validationError).length > 0 && (
                                    <div className="col-md-12">
                                        {
                                        Object.entries(validationError).map(([key, value])=>(

                                        <div key={key} className="alert alert-danger">
                                            {value}
                                        </div>
                                        ))
                                        }
                                    </div>
                                    )
                                    }
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
}



export default Contact
