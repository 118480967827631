import React, { Component } from 'react';

class About extends Component {
    render() {
        return <section id="myv" className="pt100 pb100 bg-light-gray">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 col-md-8 col-sm-10 offset-lg-3 offset-md-2 offset-sm-1">
                    <div className="section-title text-center mb60">
                        <h2>NUESTRA VISIÓN Y MISIÓN</h2>
                        <hr className="lines"/>
                    </div>
                </div>
            </div>
            <div className="row about-boxes">
                <div className="col-lg-6 col-md-6">
                    <div className="media flex-column" style={{minHeight: "300px"}}>
                        <div className="media-icon m-auto">
                                <i className="pe-7s-exapnd2"></i>
                        </div>
                    <div className="media-body m-20px-t">
                        <h5>VISIÓN</h5>
                        <p>Ser un aliado estratégico de negocios de diferentes empresas;  apoyando al cumplimiento de sus objetivos a través de servicios que generen valor, aumenten la productividad y/o ahorro de costos.</p>
                    </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6">
                    <div className="media flex-column" style={{minHeight: "300px"}}>
                        <div className="media-icon m-auto">
                                <i className="pe-7s-note2"></i>
                        </div>
                    <div className="media-body m-20px-t">
                        <h5>MISIÓN</h5>
                        <p>En OpenCorp brindamos soluciones integrales a nuestros clientes: Nos desarrollamos como aliados estratégicos de servicios con el objetivo de  apoyar a  nuestros  clientes a ser más rentables y eficientes en su negocio central.</p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    }
}


export default About
