import React from 'react';
import HeroHalf from './home-components/hero-half';
import Valores from './home-components/valores';
import MyV from './home-components/myv';
import Experiencia from './home-components/experiencia';
import Servicios from './home-components/servicios';
import Contact from './home-components/contact';



const HomeHalf = () => {
    return <div>
        <HeroHalf />
        <Valores />
        <MyV />
        <Experiencia />
        <Servicios />
        <Contact />
    </div>
}


export default HomeHalf
